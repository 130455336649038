<template>
  <div class="refunds">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="row">
          <div class="col half">
            <h1 style="visibility:hidden; height: 0px; margin: 0">
              Refunds & Returns
            </h1>
            <h3>Ticket Buyers</h3>
            <p>
              We issue refunds for digital products only if authorized by ticket
              planner in writing. To be clear, the event planner must authorize
              a refund, outside of technical issues with INCREDEVENT‘s features.
            </p>
            <p>
              We are a merchant and trust account processing payments for the
              event planner, and do not have the authority to release refunds to
              ticket buyers. Do not contact INCREDEVENT for refunds related to
              the event. If the planner allows refunds you will be able to
              submit a claim for one by going to Manage Tickets.
            </p>
            <p>
              We recommend contacting us for assistance if you experience any
              issues receiving or downloading our tickets if you cannot find
              them in your junk mail or in Ticket History on INCREDEVENT. If you
              were charged but didn’t receive tickets this is most likely a
              pre-authorization by the bank, and INCREDEVENT hasn’t charged you.
            </p>
            <p>
              We do not offer refunds based on experience, as this is a
              subjective factor.
            </p>
            <h3>Attendance & Understanding</h3>
              <p>If you choose not to attend an event, this not grounds for refund. If you do not attend an event
                because you do not understand the instructions, this is not grounds for a refund. If you fail to attend
                any in-person or virtual event that you have made a purchase for there will be no refunds under any
                circumstances.</p>
              <p> As the consumer, you agree to make a purchase and it is your right and responsibility to ask questions
                about said purchase if you are unsure of how something may or may not work as different platforms
                provide services differently.</p>
              <p>It is your responsibility to ask for support regarding platform issues that you are unsure of. We do
                not provide support for any 3rd party.</p>
          </div>
          <div class="col half">
            <h3>Event Planners</h3>
            <p>
              In the event that you (as a Event Planner) cancel your event,
              INCREDEVENT may have to bill you for fees that we incur as a
              result of hosting your event. INCREDEVENT will make its best
              efforts to recoup all fees from merchant accounts, however if fees
              are incurred as a result of your event, those fees will be passed
              onto you, the planner. Invoices for fees must be paid within
              7-days.
            </p>
            <h3>Contact Us</h3>
            <p>
              If you have any questions about our Returns and Refunds Policy,
              please
              <router-link to="/contact-us">contact us.</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.refunds {
  min-height: 85vh;
  background-color: var(--content-background);
}

.row {
  margin: 0;
  width: initial !important;

  a {
    text-decoration: underline !important;
  }
}

h3 {
  margin: 0;
  margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
  // #Content .content-inner {
  //   width: 88%;
  // }
}
</style>

<script>
export default {
  name: "refunds",
  head() {
    return {
      title: { inner: "Refunds & Returns" },
      meta: [
        {
          hid: "description",
          property: "description",
          content:
            "Looking to process a refund? If the event planner offers the option, it’s available on INCREDEVENT!",
        },
        {
          hid: "og:description",
          property: "og:description",
          content:
            "Looking to process a refund? If the event planner offers the option, it’s available on INCREDEVENT!",
        },
      ],
    };
  },
  created() {
    this.$store.commit("setTitle", "Refunds & Returns");
  },
};
</script>
